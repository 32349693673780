<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Archiv uživatelů
          <v-spacer></v-spacer>
          <v-form v-model="searchValid" class="d-flex align-center">
            <v-text-field
              ref="searchInput"
              v-model.trim="search"
              append-icon="mdi-magnify"
              :rules="[validation.required]"
              label="Vyhledat"
              single-line
              hide-details
              outlined
              dense
              class="rounded-0"
            ></v-text-field>
            <v-select
              outlined
              class="rounded-0"
              dense
              single-line
              hide-details
              ref="searchTypeInput"
              label="Vyhledat podle"
              :rules="[validation.required]"
              v-model="searchType"
              :items="searchTypes"
            >
            </v-select>
            <v-btn
              color="primary"
              :disabled="!searchValid || runningRequest"
              @click="filterBySearch"
              class="rounded-0"
            >Vyhledat</v-btn>
            <v-btn
              color="error"
              @click="resetSearch()"
              class="rounded-0"
              :disabled="!activeSearch || runningRequest"
            >
              Zrušit filtr
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
          :loading="runningRequest"
          :server-items-length="totalUsers"
          :options.sync="options"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.street="{ item }">
            {{item.street}} {{item.streetNumber}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import archiveHelpers from '@/mixins/local/archiveHelpers'
import { mapActions } from 'vuex'

export default {
  name: 'Users',
  mixins: [archiveHelpers],
  data () {
    return {
      search: '',
      searchType: null,
      activeSearch: false,
      searchValid: true,
      deleteDialog: false,
      headers: [
        { text: 'Jméno', align: 'start', value: 'firstName' },
        { text: 'Příjmení', value: 'lastName' },
        { text: 'ID', value: 'id' },
        { text: 'Telefon', value: 'phone' },
        { text: 'Telefon 2', value: 'phone2' },
        { text: 'Ulice a čp.', value: 'street' },
        { text: 'PSČ', value: 'zip' },
        { text: 'Město', value: 'city' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      searchTypes: [],
      totalUsers: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      users: [],
      runningRequest: false,
      exactRoute: true,
      userToDelete: {}
    }
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.loadUsers()
      }
    }
  },
  methods: {
    /**
     * Debounced getUsers
     * @return void
     */
    loadUsers () {},
    /**
     * Loads data from backend
     *
     * response: {
     *   users: {
     *     city: String | null,
     *     email: null
     *     first_name: String | null
     *     id: Number
     *     last_name: String | null
     *     phone: String | null
     *     phone2: String | null
     *     street: String | null
     *     zip: String | null
     *   },
     *   total: Number
     * }
     * @return void
     */
    getUsers () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      this.$http.get('/admin/users/archive', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.users = res.data.hosts
          this.totalUsers = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    /**
     * Sends request with search string and type
     * @return void
     */
    filterBySearch () {
      this.activeSearch = true
      this.getUsers()
    },
    /**
     * Resets search string and type, reloads archived users
     * @return void
     */
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.activeSearch = false
      this.getUsers()
    },
    /**
     * Redirects to user detail page
     * @param {Object} user
     * @param {Number} user.id
     * @return void
     */
    redirectToDetail (user) {
      this.$router.push({
        name: 'admin.user-archive.user',
        params: {
          id: user.id
        }
      })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  mounted () {
    this.searchTypes = this.headers.slice(0, -2)
    this.getUsers()
    this.loadUsers = this.debounce(this.getUsers, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
